import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="404: Nicht gefunden"
      description="Die gesuchte Seite konnte nicht gefunden werden."
    />
    <article>
      <h1>404: Seite nicht gefunden</h1>
      <p>Diese Seite gibt es (noch) nicht. Schau doch später nochmal vorbei!</p>
      <p>
        <Link to="/">Zurück zum Anfang.</Link>
      </p>
    </article>
  </Layout>
);

export default NotFoundPage;
